import WebFont from 'webfontloader'

export default fallBackByFamily => {
	const families = Object.keys(fallBackByFamily)
	const fallBackByFamilyName = families.reduce(
		(byName, fam) => ({
			...byName,
			[fam.split(':')[0]]: fallBackByFamily[fam],
		}),
		{},
	)
	WebFont.load({
		google: { families },
		fontinactive: family => {
			const localFontUrl = fallBackByFamilyName[family]
			if (localFontUrl) {
				WebFont.load({
					custom: {
						families: [family],
						urls: [localFontUrl],
					},
				})
			}
		},
	})
}
