import dosis from 'stylesheets/external/fonts/dosis.css'
import droidSans from 'stylesheets/external/fonts/droid-sans.css'
import material from 'stylesheets/external/fonts/material-icons.css'
import materialSymbols from 'stylesheets/external/fonts/material-symbols.css'
import openSans from 'stylesheets/external/fonts/open-sans.css'
import roboto from 'stylesheets/external/fonts/roboto.css'
import sourceSans from 'stylesheets/external/fonts/source-sans-pro.css'
import ubuntu from 'stylesheets/external/fonts/ubuntu.css'
import fontLoader from './utils/fontLoader'

fontLoader({
	'Ubuntu:400': ubuntu,
	'Open Sans:300,400,600,700': openSans,
	'Source Sans Pro:200,300,400,600,700,900': sourceSans,
	'Material Icons': material,
	'Material Symbols Outlined': materialSymbols,
	'Droid Sans:400,700': droidSans,
	'Roboto:100,300,400,500,700': roboto,
	'Dosis:300,400,500,600,700': dosis,
})
